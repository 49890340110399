// File: plugins/socket.io.client.js
import io from 'socket.io-client';

export default defineNuxtPlugin((nuxtApp) => {
  const ws_url = 'https://css-websocket-server-a9dlm.ondigitalocean.app'; // Replace with your server's URL
  // const ws_url = 'http://localhost:3008'; // Your WebSocket server URL
  const {callcenterMessages} = useCallcenterWebSocket();
  let socketCallcenter = null;
  let isCallcenterInitialized = false; // Track if WebSocket connection is initialized
  let isCallcenterSubscribed = false; // Track if call center events are subscribed
  let subscribedCallcenterId = null;
  let storedToken, storedAccountId, storedCallcenterId;

  // Initialize WebSocket connection
  function initCallcenterSocketConnection() {
    if (!socketCallcenter && !isCallcenterInitialized) {
      console.log('Initializing Callcenter WebSocket connection...');
      socketCallcenter = io(ws_url, { transports: ['websocket'] });
      isCallcenterInitialized = true;

      socketCallcenter.on('connect', () => {
        console.log('Connected to WebSocket Callcenter');
        subscribeToCallcenterEvents(storedToken, storedAccountId, storedCallcenterId);
      });

      socketCallcenter.on('disconnect', () => {
        console.log('WebSocket disconnected');
        isCallcenterInitialized = false;
        isCallcenterSubscribed = false;
      });

      socketCallcenter.on('message', (data) => {
        console.log('Message CC received:', data);
        callcenterMessages.value = data;
      });
    }
  }

  // Subscribe to user events
  function subscribeToCallcenterEvents(token, account_id, callcenter_id) {
    storedToken = token;
    storedAccountId = account_id;
    storedCallcenterId = callcenter_id;
    initCallcenterSocketConnection();
    if (!isCallcenterSubscribed && socketCallcenter) {
      console.log(`Subscribing to callcenter events with account_id: ${account_id}, callcenter_id: ${callcenter_id}`);
      subscribedCallcenterId = callcenter_id;
      socketCallcenter.emit('subscribe_callcenter', token, account_id, callcenter_id);
      isCallcenterSubscribed = true; // Mark user events as subscribed
    } else {
      if (callcenter_id == subscribedCallcenterId){
        console.log('Callcenter user events are already subscribed.');
      } else {
        disconnectCallcenterSocket()

        console.log('Resubscribing to another callcenter');
        subscribeToCallcenterEvents(token, account_id, callcenter_id);
      }
      
    }
  }

  // Process incoming data (if needed)
  function processData(data) {
    let object;
    try {
      object = JSON.parse(data);
      console.log('Processed data:', object);
    } catch (error) {
      console.log('Error processing data:', error);
    }
  }
  function disconnectCallcenterSocket() {
    if (socketCallcenter) {
      console.log('Disconnecting from WebSocket');
      socketCallcenter.disconnect();
      socketCallcenter = null;
      isCallcenterInitialized = false;
      isCallcenterSubscribed = false;
      subscribedCallcenterId = null;
      
    }
  }

  // Provide the socket and methods globally
  nuxtApp.provide('socketCallcenter', {
    socketCallcenter,
    initCallcenterSocketConnection,
    subscribeToCallcenterEvents,
    processData,

  });
});
