const callcenterMessages = ref([]);

export const useCallcenterWebSocket = () => {
  const { $socketCallcenter } = useNuxtApp(); // Access the socket from the Nuxt plugin

  // Initialize and subscribe to user events
  const initCallcenterWebSocket = (token, account_id, account_user_id) => {
    $socketCallcenter.subscribeToCallcenterEvents(token, account_id, account_user_id);
  };

  return {
    callcenterMessages,
    initCallcenterWebSocket,
  };
};