export default defineNuxtPlugin(() => {
  const uploadImageToS3 = async (file, fileName) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileName', fileName);
    console.log('File:', file);
    try {
      const token = sessionStorage.getItem('token');
      const response = await $fetch(`/api/protected/my/upload-avatar`, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `${token}`, // Ensure Bearer prefix if needed
        },
      });

      if (!response) {
        throw new Error('Failed to upload image to API');
      }

      const data = await response;
      return data.fileUrl; // Assume the API response includes the S3 URL of the uploaded file
    } catch (error) {
      console.error('API upload error:', error);
      throw new Error('Failed to upload image to API');
    }
  };

  return {
    provide: {
      uploadImageToS3,
    },
  };
});



// import { useRuntimeConfig } from '#imports';

// export default defineNuxtPlugin(() => {
//   const config = useRuntimeConfig();

//   const uploadImageToS3 = async (file, fileName) => {
//     const formData = new FormData();
//     formData.append('file', file);
//     formData.append('fileName', fileName);

//     try {
//       const response = await fetch(`${config.public.apiBaseUrl}/upload`, {
//         method: 'POST',
//         body: formData,
//       });

//       if (!response.ok) {
//         throw new Error('Failed to upload image to API');
//       }

//       const data = await response.json();
//       return data.fileUrl; // Assume the API response includes the S3 URL of the uploaded file
//     } catch (error) {
//       console.error('API upload error:', error);
//       throw new Error('Failed to upload image to API');
//     }
//   };

//   return {
//     provide: {
//       uploadImageToS3,
//     },
//   };
// });

// import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3';
// // import { useRuntimeConfig } from '#imports';
// import {getServerEnv} from '~~/server/utils/serverEnv'

// export default defineNuxtPlugin(() => {
//   const config = useRuntimeConfig();
// //   const config = getServerEnv();

//   const s3Client = new S3Client({
//     region: config.public.awsRegion,
//     credentials: {
//       accessKeyId: config.public.awsAccessKeyId,
//       secretAccessKey: config.public.awsSecretAccessKey,
//     },
//   });

//   const uploadImageToS3 = async (file, fileName) => {
//     const uploadParams = {
//       Bucket: config.public.awsS3BucketName,
//       Key: `uploads/${fileName}`, // adjust path as needed
//       Body: file,
//       ContentType: file.type,
//       // ACL: 'public-read', // optional: make file publicly accessible
//     };

//     try {
//       const command = new PutObjectCommand(uploadParams);
//       await s3Client.send(command);
//       return `https://${config.public.awsS3BucketName}.s3.amazonaws.com/uploads/${fileName}`;
//     } catch (error) {
//       console.error('S3 upload error:', error);
//       throw new Error('Failed to upload image to S3');
//     }
//   };

//   return {
//     provide: {
//       uploadImageToS3,
//     },
//   };
// });