const userMessages = ref(); // Moved outside the function

export const useUserWebSocket = () => {
  const { $socket } = useNuxtApp(); // Access the socket from the Nuxt plugin

  // Initialize and subscribe to user events
  const initUserWebSocket = (token, account_id, account_user_id) => {
    $socket.subscribeToUserEvents(token, account_id, account_user_id);
  };

  return {
    userMessages,
    initUserWebSocket
  };
};