// File: plugins/socket.io.client.js
import io from 'socket.io-client';

export default defineNuxtPlugin((nuxtApp) => {
  
  const ws_url = 'https://css-websocket-server-a9dlm.ondigitalocean.app'; // Replace with your server's URL
  // const ws_url = 'http://localhost:3008'; // Your WebSocket server URL
  const { userMessages, initUserWebSocket } = useUserWebSocket();
  let socket = null;
  let isInitialized = false; // Track if WebSocket connection is initialized
  let isUserSubscribed = false; // Track if user events are subscribed
  let storedToken, storedAccountId, storedAccountUserId;
  // Initialize WebSocket connection
  function initUserSocketConnection() {
    if (!socket && !isInitialized) {
      console.log('Initializing WebSocket connection...');
      socket = io(ws_url, { transports: ['websocket'] });
      isInitialized = true;

      socket.on('connect', () => {
        console.log('Connected to WebSocket');
        subscribeToUserEvents(storedToken, storedAccountId, storedAccountUserId)
        
      });

      socket.on('disconnect', () => {
        console.log('WebSocket disconnected');
        isInitialized = false;
        isUserSubscribed = false;
      });

      socket.on('message', (data) => {
        console.log('Message received:', data);
        userMessages.value = data;
        console.log(userMessages.value);
      });
    }
  }

  // Subscribe to user events
  function subscribeToUserEvents(token, account_id, account_user_id) {
    storedToken = token;
    storedAccountId = account_id;
    storedAccountUserId = account_user_id;
    initUserSocketConnection();
    if (!isUserSubscribed && socket) {
      console.log(`Subscribing to user events with account_id: ${account_id}, user_id: ${account_user_id}`);
      socket.emit('subscribe_user', token, account_id, account_user_id);
      isUserSubscribed = true; // Mark user events as subscribed
    } else {
      console.log('User events are already subscribed.');
    }
  }

  // Process incoming data (if needed)
  function processData(data) {
    let object;
    try {
      object = JSON.parse(data);
      console.log('Processed data:', object);
    } catch (error) {
      console.log('Error processing data:', error);
    }
  }

  // Provide the socket and methods globally
  nuxtApp.provide('socket', {
    socket,
    initUserSocketConnection,
    subscribeToUserEvents,
    processData,
  });
});
